import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {Collapse} from 'bootstrap';
import sheet from '../style.scss';
import $ from 'jquery';

class Page extends React.Component {
  user = undefined;
  avatar;

  constructor(props) {
    super(props);
    try{
        this.user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : undefined;
    }catch(e){}
    this.avatar = this.user  != undefined ? process.env.REACT_APP_BACKEND_URL+"/users/"+this.user.id+"/avatar/image" : "/images/avatar.png";
  }
  renderMainMenuProfile(){
    return(
        <a href="/profile" className="profile">
            <img src={this.avatar} className="avatar"/>
        </a>
        )
  }
  renderMainMenu(){
  return (
          <nav id="navbar" className="navbar start-0 w-100 navbar-expand-lg bg-light fixed-top">
              <div className="container-fluid">
               <button className="navbar-toggler" type="button" onClick={() => this.setState({toggle: !this.state.toggle})} data-bs-toggle="collapse" data-bs-target="#navbarToggle" aria-controls="navbarToggle" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                <a className="navbar-brand ms-3" href="/">crowdhiring</a>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarToggle">
                        <ul className="navbar-nav ms-auto">
                            {/*<li className="nav-item">*/}
                            {/*  <a className="nav-link" href="#">Jobs</a>*/}
                            {/*</li>*/}
                            <li className="nav-item">
                                <a className="nav-link" href="/find/talents/">FIND TALENTS</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " href="/recommendation/create">RECOMmEND</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " href={"CrowdHiring_20241013.pdf"}>about</a>
                            </li>
                        </ul>
                    </div>
                  <form className="d-flex">
                      {this.user != undefined ?
                          this.renderMainMenuProfile()
                    :
                        <a href="/auth" className="login btn btn-dark rounded p-3">log in</a>
                    }
                  </form>
              </div>
            </nav>
   )
  }
  renderMain(){
    return ("");
  }
  render() {
    return (
      <div id="page">
        {this.renderMainMenu()}
        <div className="page-content">
            {this.renderMain()}
        </div>
      </div>
    );
  }
}
export default Page;